<template>
  <div class="stack-element">
    <div
      class="stack-element__child"
      v-for="(stack, index) in element.children"
      :key="index"
    >
      <div v-if="stack.type === 'stack'">
        <StackElement
          :element="stack"
          :temp-element="tempElement.children[index]"
          :style="stack.css"
        />
      </div>

      <div class="stack-element__element-label" v-if="stack.type === 'text'">
        {{ stack.name }}
      </div>
      <textarea-autosize
        class="stack-element__text"
        :value="getText(stack.text)"
        @input="updateStackText($event, index)"
        v-if="stack.type === 'text'"
      />

      <div
        class="stack-element__picture"
        v-if="stack.type === 'image' && stack.overrides != 0"
      >
        <div class="stack-element__picture-main-options">
          <div class="stack-element__element-label">
            {{ stack.name }}
          </div>
          <div class="stack-element__element-load-btns">
            <label
              :for="
                `stack-element__editor-element-bar-bpicture-upload-modal-tn-${index}`
              "
              class="stack-element__upload-btn"
            >
              {{ $t("pages.editor.uploadPhoto") }}
            </label>
            <input
              :id="
                `stack-element__editor-element-bar-bpicture-upload-modal-tn-${index}`
              "
              type="file"
              ref="imageFile"
              @change="loadImage($event, index)"
              accept=".png,.jpg,.jpeg"
            />
            <!-- <Button
              class="stack-element__element-generateImage-btn"
              icon="sparkle"
              @click="openGenerateImageModal(stack)"
              theme="secondary"
              v-if="isDseFeature"
            /> -->
          </div>
        </div>
        <div class="stack-element__feedback">
          {{ imageFeedback }}
        </div>
        <div
          class="stack-element__element-options stack-element__element-options__image"
        >
          <Button
            class="stack-element__element-image-settings-btn"
            @click="toggleImageSettings(index)"
            theme="expandCollapse"
            icon="Down"
          >
            {{ $t("pages.editor.imageSettings") }}
          </Button>
          <div
            v-if="tempElement.children[index].showSettings"
            class="stack-element__element-options__image-settings"
          >
            <div
              class="stack-element__element-options__image-settings__image-fitting"
            >
              <Button
                class="stack-element__element-option"
                :theme="
                  stack.classes.imageStyles === 'contain'
                    ? 'primary'
                    : 'background'
                "
                @click="stack.classes.imageStyles = 'contain'"
              >
                {{ $t("pages.editor.contain") }}
              </Button>
              <Button
                class="stack-element__element-option"
                :theme="
                  stack.classes.imageStyles === 'cover'
                    ? 'primary'
                    : 'background'
                "
                @click="stack.classes.imageStyles = 'cover'"
              >
                {{ $t("pages.editor.cover") }}
              </Button>
              <Button
                class="stack-element__element-crop-btn"
                icon="Crop"
                @click="cropImage(index)"
                theme="secondary"
              />
            </div>
            <!-- <Button
              class="stack-element__element-bg-btn"
              icon="Magic-Wand"
              theme="secondary"
              @click="openCreditModal(stack)"
              v-if="isDseFeature"
              >{{ $t("pages.editor.removeBG") }}
            </Button> -->
          </div>
        </div>
      </div>

      <div
        class="stack-element__qr"
        v-if="stack.type === 'qr' && stack.overrides != 0"
      >
        <div class="stack-element__qr-label">
          QR Code Url
        </div>
        <input class="stack-element__qr-input" v-model="stack.url" />
      </div>

      <div
        class="stack-element__video"
        v-if="stack.video && stack.overrides != 0"
      >
        <div class="stack-element__video-main-options">
          <div class="stack-element__element-label">
            {{ stack.name }}
          </div>
          <label
            :for="`stack-element__video-upload-btn-${index}`"
            class="stack-element__upload-btn"
          >
            {{ $t("pages.editor.uploadVideo") }}
          </label>
          <input
            :id="`stack-element__video-upload-btn-${index}`"
            type="file"
            ref="file"
            @change="loadVideo($event, index)"
            accept=".mov,.mp4"
          />
        </div>
        <div class="stack-element__feedback">
          {{ videoFeedback }}
        </div>

        <div class="stack-element__element-options">
          <Button
            class="stack-element__element-option"
            :theme="
              stack.classes.imageStyles === 'contain' ? 'primary' : 'background'
            "
            @click="stack.classes.imageStyles = 'contain'"
          >
            {{ $t("pages.editor.contain") }}
          </Button>
          <Button
            class="stack-element__element-option"
            :theme="
              stack.classes.imageStyles === 'cover' ? 'primary' : 'background'
            "
            @click="stack.classes.imageStyles = 'cover'"
          >
            {{ $t("pages.editor.cover") }}
          </Button>
        </div>
      </div>
    </div>

    <!-- New Modal for Generate Image Feature -->
    <Modal
      v-if="showGenerateImageModal"
      class="stack-element__generateImage-modal"
      @close="showGenerateImageModal = false"
      :heading="$t('pages.editor.generateImage.heading')"
    >
      <template #modal-content>
        <div
          class="stack-element__generateImage-modal__body"
          v-if="credits > 0"
        >
          <div class="stack-element__generateImage-modal__body-form">
            <input
              type="text"
              v-model="generateImagePrompt"
              :placeholder="$t('pages.editor.generateImage.placeholder')"
              class="editor__input"
            />
            <Button
              class="button"
              @click="submitGenerateImagePrompt"
              :disabled="isSubmitting"
            >
              <span v-if="isSubmitting">
                <i class="fa fa-spinner fa-spin"></i>
                {{ $t("pages.editor.creditModal.loadingState") }}
              </span>
              <span v-else>
                <inline-svg
                  class="dashboard__create-slide-btn__icon"
                  :src="require(`../assets/icons/Magic-Wand.svg`)"
                  aria-label="Create"
                ></inline-svg>
              </span>
            </Button>
          </div>
          <div class="stack-element__feedback" v-if="showGenerateImageFeedback">
            {{ $t("pages.editor.generateImage.feedback") }}
          </div>
        </div>
        <span class="stack-element__generateImage-modal__credits">
          {{ credits }} {{ $t("pages.editor.creditModal.infoSubTitle") }}
        </span>
      </template>
    </Modal>

    <Modal
      v-if="showCreditModal"
      class="stack-element__credit-modal"
      @close="showCreditModal = false"
      :heading="$t('pages.editor.creditModal.title')"
    >
      <template #modal-content>
        <div class="stack-element__credit-modal-icon"></div>
        <div class="stack-element__credit-modal-info-title">
          {{ $t("pages.editor.creditModal.infoTitle") }}
        </div>
        <div class="stack-element__credit-modal-info-sub-title">
          {{ credits }} {{ $t("pages.editor.creditModal.infoSubTitle") }}
        </div>
        <Button
          class="stack-element__credit-modal-confirm-btn"
          @click="onRemoveBackground"
          v-if="credits > 0"
          :disabled="isSubmitting"
        >
          <span v-if="isSubmitting">
            <i class="fa fa-spinner fa-spin"></i>
            {{ $t("pages.editor.creditModal.removingState") }}
          </span>
          <span v-else>
            {{ $t("pages.editor.creditModal.removeBtn") }}
          </span>
        </Button>
        <Button
          class="stack-element__credit-modal-confirm-btn"
          @click="goToSupport"
          v-else
          theme="secondary"
        >
          {{ $t("pages.editor.creditModal.contactBtn") }}
        </Button>
      </template>
    </Modal>

    <Modal
      class="stack-element__picture-upload-modal"
      v-if="showPictureModal"
      @close="showPictureModal = false"
      :heading="$t('pages.editor.pictureModal.title')"
    >
      <div
        class="stack-element__picture-upload-modal-content"
        slot="modal-content"
      >
        <div class="stack-element__picture-upload-modal-field">
          <vue-croppie
            class="stack-element__picture-upload-modal-cropper"
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="false"
            :enforceBoundary="false"
            :boundary="boundary"
            :viewport="viewport"
          >
          </vue-croppie>
        </div>

        <Button
          class="stack-element__picture-upload-modal-set-btn"
          @click="setImage"
        >
          {{ $t("pages.editor.crop") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "StackElement",
  components: {
    Modal,
    Button
  },
  props: {
    element: {
      type: Object,
      default: () => {}
    },
    tempElement: {
      type: Object,
      default: () => {}
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    imageStyles: ["contain", "cover"],
    showCreditModal: false,
    showPictureModal: false,
    showGenerateImageModal: false,
    showGenerateContentModal: false,
    generateImagePrompt: "",
    generateContentPrompt: "",
    currentElement: null,
    imageFeedback: "",
    videoFeedback: "",
    elementData: {
      index: 0,
      width: "0px",
      height: "0px"
    },
    targetElement: null,
    isSubmitting: false,
    showGenerateImageFeedback: false,
    showGenerateContentFeedback: false
  }),
  computed: {
    ...mapGetters("teams", {
      credits: "getCredits",
      currentTeam: "getCurrentTeam"
    }),
    isDseFeature() {
      return this.currentTeam?.dseFeature ?? false;
    },
    boundary() {
      return {
        width: this.elementData.width,
        height: this.elementData.height
      };
    },
    viewport() {
      return {
        width: this.elementData.width,
        height: this.elementData.height,
        type: "square"
      };
    }
  },
  methods: {
    ...mapActions("teams", ["fetchTeamCredits"]),
    ...mapActions("slides", ["removeBackground"]),
    getText(text) {
      let element = text;
      element = element.replace(/<div>/g, "\n");
      element = element.replace(/<br>/g, "\n");
      element = element.replace(/&nbsp;/g, " ");
      element = element.replace(/<\/div>/g, "");
      return element;
    },
    updateStackText(e, index) {
      this.element.children[index].text = e;
    },
    cropImage(index) {
      this.showPictureModal = true;

      const maxWidth = 1000;
      const maxHeight = 600;

      const imageWidth =
        this.element.children[index].css[this.orientation].width === "auto"
          ? this.calculateWidthAutoDimensions(
              this.element.children[index].css[this.orientation].left.replace(
                "px",
                ""
              ),
              this.element.children[index].css[this.orientation].right.replace(
                "px",
                ""
              )
            )
          : this.element.children[index].css[this.orientation].width.replace(
              "px",
              ""
            );
      const imageHeight =
        this.element.children[index].css[this.orientation].height === "auto"
          ? this.calculateHeighthAutoDimensions(
              this.element.children[index].css[this.orientation].top.replace(
                "px",
                ""
              ),
              this.element.children[index].css[this.orientation].bottom.replace(
                "px",
                ""
              )
            )
          : this.element.children[index].css[this.orientation].height.replace(
              "px",
              ""
            );

      const widthRatio = maxWidth / imageWidth;
      const heightRatio = maxHeight / imageHeight;
      const scale = Math.min(widthRatio, heightRatio);

      const croppieWidth =
        imageWidth > maxWidth || imageHeight > maxHeight
          ? imageWidth * scale
          : imageWidth;
      const croppieHeight =
        imageWidth > maxWidth || imageHeight > maxHeight
          ? imageHeight * scale
          : imageHeight;

      this.elementData = {
        index: index,
        width: croppieWidth + "px",
        height: croppieHeight + "px"
      };

      this.$nextTick(() => {
        this.$refs.croppieRef.bind({
          url: this.element.children[index].image.src
        });
      });
    },
    loadImage(e, index) {
      const maxFileSize = 1024 * 1024 * 10; // 10MB
      const allowedExtensions = ["png", "jpg", "jpeg"];

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const extension = files[0].name.split(".").pop();
      const size = files[0].size;

      if (allowedExtensions.includes(extension.toLowerCase())) {
        this.imageFeedback = "";

        if (size < maxFileSize) {
          this.imageFeedback = "";
          let reader = new FileReader();

          reader.onload = e => {
            this.element.children[index].image.src = e.target.result;
            this.element.children[index].image.isChanged = true;
          };

          reader.readAsDataURL(files[0]);
          e.target.value = "";
          setTimeout(() => {
            this.cropImage(index);
          }, 500);
        } else {
          this.imageFeedback = this.$t("pages.editor.imageMaxFileSize");
        }
      } else {
        this.imageFeedback = this.$t("pages.editor.imageFileType");
      }
    },
    setImage() {
      let options = {
        type: "base64",
        size: {
          width: this.elementData.width.replace("px", ""),
          height: this.elementData.height.replace("px", "")
        }
      };
      this.$refs.croppieRef.result(options, output => {
        this.element.children[this.elementData.index].image.src = output;
        this.element.children[this.elementData.index].image.isChanged = true;
      });
      this.showPictureModal = false;
    },
    checkIfPlaceholder(element) {
      if (typeof element.video.src === "string") {
        return element.video.src.includes("img/video-placeholder");
      }
    },
    loadVideo(e, index) {
      const maxFileSize = 1024 * 1024 * 1024; // 1 GB
      const allowedExtensions = ["mov", "mp4"];
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const extension = files[0].name.split(".").pop();
      const size = files[0].size;

      if (allowedExtensions.includes(extension.toLowerCase())) {
        this.videoFeedback = "";

        if (size < maxFileSize) {
          this.videoFeedback = "";

          this.element.children[index].video.src = files[0];
          this.element.children[index].video.isChanged = true;
          this.element.children[index].video.url = URL.createObjectURL(
            files[0]
          );
          this.setDuration();
        } else {
          this.videoFeedback = this.$t("pages.editor.videoMaxFileSize");
        }
      } else {
        this.videoFeedback = this.$t("pages.editor.videoFileType");
      }
    },
    async setDuration() {
      let videos = [];
      for (const element of this.element.children) {
        if (element.type === "video" && !this.checkIfPlaceholder(element)) {
          if (element.video.url) {
            let x = await this.getVideoDuration(element.video.src, true);
            videos.push(x);
          } else {
            let x = await this.getVideoDuration(element.video.src, false);
            videos.push(x);
          }
        }
      }
      this.$emit("duration-changed", Math.max(...videos));
    },
    getVideoDuration(file, blob) {
      return new Promise((resolve, reject) => {
        try {
          let video = document.createElement("video");
          video.preload = "metadata";

          video.onloadedmetadata = function() {
            resolve(Math.floor(video.duration));
          };

          video.onerror = function() {
            reject("Invalid video. Please select a video file.");
          };
          if (blob) {
            video.src = window.URL.createObjectURL(file);
          } else {
            video.src = file;
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    toggleImageSettings(index) {
      this.$set(
        this.tempElement.children[index],
        "showSettings",
        !this.tempElement.children[index].showSettings
      );

      console.log("toggle", this.tempElement.children[index].showSettings);
    },
    openGenerateImageModal(element) {
      this.fetchTeamCredits().then(() => {
        this.currentElement = element;
        this.showGenerateImageModal = true;
      });
    },
    submitGenerateImagePrompt() {
      this.showGenerateImageFeedback = false;
      this.isSubmitting = true;
      const url =
        "https://europe-west3-lobbyspace2.cloudfunctions.net/generateImage";
      const data = {
        prompt: this.generateImagePrompt,
        teamId: this.currentTeam.id
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (!response.ok) {
            // Throw an error if the response status is not OK
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          const imageUrl = URL.createObjectURL(blob);
          console.log("Success:", imageUrl);
          // Update the image source here
          this.currentElement.image.src = imageUrl;
          this.currentElement.image.isChanged = true;
          this.isSubmitting = false;
          this.showGenerateImageModal = false;
        })
        .catch(error => {
          this.showGenerateImageFeedback = true;
          console.error("Error:", error);
          this.isSubmitting = false;
        });
    },
    openCreditModal(element) {
      this.currentElement = element;
      this.fetchTeamCredits().then(() => {
        this.showCreditModal = true;
      });
    },
    onRemoveBackground() {
      this.isSubmitting = true;
      let payload = {
        team: this.currentTeam.id,
        url: this.currentElement.image.src
      };

      this.removeBackground(payload)
        .then(newImage => {
          this.currentElement.image.src = newImage;
          this.currentElement.image.isChanged = true;
          this.currentElement = null;
          this.showCreditModal = false;
          this.isSubmitting = false;
        })
        .catch(error => {
          console.log(error);
          this.showCreditModal = false;
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style lang="scss">
.stack-element {
  color: #000 !important;
  padding: 0px !important;
  width: 100% !important;

  &__child {
    width: 100%;
  }

  &__text {
    @apply bg-ls-gray-100 rounded-lg p-2 mb-4 text-black;
    width: 100% !important;
    resize: none;
  }

  &__picture,
  &__video {
    &-main-options {
      @apply p-3;
    }
    @apply border bg-ls-gray-100 rounded-lg border-ls-gray-200 mb-4;
  }

  &__element-label {
    @apply text-xs font-semibold mb-2;
  }

  &__qr {
    margin-bottom: 16px;

    &-label {
      @apply text-xs font-semibold mb-2;
    }

    &-input {
      @apply bg-ls-gray-100 rounded-lg px-2 py-3 w-full;
      font-size: 14px;
      line-height: 20px;
    }

    &-button {
      @apply w-full mt-3;
    }
  }

  &__element-load-btns {
    @apply flex;
  }

  &__upload-btn {
    @apply px-3 py-2 cursor-pointer bg-white border-ls-gray-400 border rounded-lg text-xs font-semibold w-full text-center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }

  input[type="file"] {
    display: none;
  }

  &__element-generateImage-btn {
    margin-left: 8px;
    svg * {
      fill: #af5fff;
    }
    .btn__icon {
      margin: 0;
    }
  }

  &__element-crop-btn {
    .btn__icon {
      margin: 0;
    }
  }

  &__element-options {
    @apply flex mt-2 rounded-lg;
  }

  &__element-option {
    @apply w-full;
  }

  &__element-image-settings-btn {
    width: 100%;
  }

  &__element-options__image {
    @apply p-3 bg-white;
    border-radius: 0 !important;
    flex-direction: column;
  }

  &__element-options__image-settings {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    &__image-fitting {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }
  }

  &__upload-btn {
    @apply px-3 py-2 cursor-pointer bg-white border-ls-gray-400 border rounded-lg text-xs font-semibold w-full text-center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }

  &__feedback {
    @apply text-ls-red mt-2 text-xs font-semibold;
  }
}
</style>
