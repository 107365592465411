import firebase from "firebase";
// import axios from "axios";
import "firebase/storage";
import "firebase/functions";
import { db } from "../firebase/init";
import _ from "lodash";
import router from "../router/index";
import { create_UUID } from "../utils/utils";
import { blobToBase64, blobUrlToBase64 } from "./helper";

function removeCssFromStacks(array) {
  if (!Array.isArray(array)) return array;

  return array.map(obj => {
    delete obj.css;

    // If the object is of type "stack" and has children, recursively process them
    if (obj.type === "stack" && obj.children) {
      obj.children = removeCssFromStacks(obj.children);
    }

    return obj;
  });
}

export default {
  namespaced: true,
  state: {
    isUploading: false,
    originPage: "/",
    widgetData: null,
    refreshSlides: false
  },
  getters: {
    getIsUploading(state) {
      return state.isUploading;
    },
    getOriginPage(state) {
      return state.originPage;
    },
    getWidgetData(state) {
      return state.widgetData;
    },
    getRefreshSlides(state) {
      return state.refreshSlides;
    }
  },
  mutations: {
    SET_IS_UPLOADING(state, payload) {
      state.isUploading = payload;
    },
    SET_ORIGIN_PAGE(state, payload) {
      state.originPage = payload;
    },
    SET_WIDGET_DATA(state, payload) {
      state.widgetData = payload;
    },
    SET_REFRESH_SLIDES(state, payload) {
      state.refreshSlides = payload;
    }
  },
  actions: {
    async removeBackground(_, payload) {
      const url =
        "https://europe-west3-lobbyspace2.cloudfunctions.net/removeBGnew";

      try {
        let imageData;

        if (
          payload.url.startsWith("data:") ||
          payload.url.startsWith("blob:")
        ) {
          imageData = payload.url.startsWith("data:")
            ? payload.url.split(",")[1]
            : await blobUrlToBase64(payload.url);
        } else {
          const response = await fetch(payload.url);
          const blob = await response.blob();
          imageData = await blobToBase64(blob);
        }

        const data = {
          teamId: payload.team,
          image: imageData
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        });
        if (!response.ok) {
          const text = await response.text();
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${text}`
          );
        }
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        console.log("Background removed successfully:", imageUrl);
        return imageUrl;
      } catch (error) {
        console.error("Error removing background:", error);
      }
    },
    setOriginPage({ commit }, link) {
      commit("SET_ORIGIN_PAGE", link);
    },
    setIsUploading({ commit }, payload) {
      commit("SET_IS_UPLOADING", payload);
    },
    setRefreshSlides({ commit }, payload) {
      commit("SET_REFRESH_SLIDES", payload);
    },
    async uploadSlide({ rootState, commit, dispatch }, slide) {
      commit("SET_IS_UPLOADING", true);
      let date = new Date();
      let seconds = date.getTime() / 1000;
      let promises = [];
      let resolvedPromises = 0;
      let slides = rootState.teams.currentTeam.slides
        ? rootState.teams.currentTeam.slides
        : [];
      const slidesLength = slides.length;
      let url = {
        image: {
          landscape: "",
          portrait: ""
        },
        video: {
          landscape: "",
          portrait: ""
        }
      };

      if (Object.keys(slide.file[slide.data.type].landscape).length !== 0) {
        const storageRef = firebase
          .storage()
          .ref()
          .child(
            `teams/${rootState.teams.currentTeam.id}/slides/${slide.data.id}/${seconds}__landscape`
          );

        let blob;
        if (slide.data.type === "image") {
          blob = await fetch(slide.file.image.landscape.src).then(r =>
            r.blob()
          );
        } else {
          blob = slide.file[slide.data.type].landscape.src;
        }

        promises.push({
          type: "landscape",
          task: storageRef.put(blob)
        });
      }

      if (Object.keys(slide.file[slide.data.type].portrait).length !== 0) {
        const storageRef = firebase
          .storage()
          .ref()
          .child(
            `teams/${rootState.teams.currentTeam.id}/slides/${slide.data.id}/${seconds}__portrait`
          );

        let blub;
        if (slide.data.type === "image") {
          blub = await fetch(slide.file.image.portrait.src).then(r => r.blob());
        } else {
          blub = slide.file[slide.data.type].portrait.src;
        }

        promises.push({
          type: "portrait",
          task: storageRef.put(blub)
        });
      }

      Promise.all(promises).then(data => {
        data.map(upload => {
          upload.task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snapshot => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              upload.type === "landscape"
                ? commit("progress/SET_PROGRESS_LANDSCAPE", progress, {
                    root: true
                  })
                : commit("progress/SET_PROGRESS_PORTRAIT", progress, {
                    root: true
                  });

              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            error => {
              console.log(error);
            },
            () => {
              upload.task.snapshot.ref.getDownloadURL().then(downloadURL => {
                url[slide.data.type][upload.type] = downloadURL;
                console.log("File available at", downloadURL);
                resolvedPromises++;

                if (resolvedPromises === promises.length) {
                  slides[slidesLength] = {
                    ...slide.data,
                    url: url,
                    originalDate: new Date(),
                    lastChangedDate: new Date(),
                    createdBy: rootState.user.userProfile.user_id,
                    editedBy: rootState.user.userProfile.user_id
                  };

                  firebase
                    .database()
                    .ref("teams/" + rootState.teams.currentTeam.id)
                    .update({
                      slides: slides
                    })
                    .then(() => {
                      commit("SET_IS_UPLOADING", false);

                      dispatch(
                        "notifications/addNotification",
                        {
                          title: "slideUploaded",
                          type: "success",
                          autoRemove: true
                        },
                        { root: true }
                      );

                      router.push({
                        name: "Editor",
                        params: {
                          file: slide.data.type,
                          isParent: "0",
                          id: slide.data.id
                        }
                      });

                      commit("SET_REFRESH_SLIDES", true);
                    });
                }
              });
            }
          );
        });
      });
    },
    async updateSlide({ rootState, commit, dispatch }, slide) {
      commit("SET_IS_UPLOADING", true);
      let date = new Date();
      let seconds = date.getTime() / 1000;
      let promises = [];
      let resolvedPromises = 0;
      let slides = rootState.teams.currentTeam.slides;
      let url = {
        image: {
          landscape: "",
          portrait: ""
        },
        video: {
          landscape: "",
          portrait: ""
        }
      };

      if (slide.updated[slide.data.type].landscape) {
        const storageRef = firebase
          .storage()
          .ref()
          .child(
            `teams/${rootState.teams.currentTeam.id}/slides/${slide.data.id}/${seconds}__landscape`
          );

        let blob;
        if (slide.data.type === "image") {
          blob = await fetch(slide.file.image.landscape.src).then(r =>
            r.blob()
          );
        } else {
          blob = slide.file[slide.data.type].landscape.src;
        }

        promises.push({
          type: "landscape",
          task: storageRef.put(blob)
        });
      } else {
        url[slide.data.type].landscape =
          slide.oldUrl[slide.data.type].landscape;
      }

      if (slide.updated[slide.data.type].portrait) {
        const storageRef = firebase
          .storage()
          .ref()
          .child(
            `teams/${rootState.teams.currentTeam.id}/slides/${slide.data.id}/${seconds}__portrait`
          );

        let blub;
        if (slide.data.type === "image") {
          blub = await fetch(slide.file.image.portrait.src).then(r => r.blob());
        } else {
          blub = slide.file[slide.data.type].portrait.src;
        }

        promises.push({
          type: "portrait",
          task: storageRef.put(blub)
        });
      } else {
        url[slide.data.type].portrait = slide.oldUrl[slide.data.type].portrait;
      }

      Promise.all(promises).then(data => {
        data.map(upload => {
          upload.task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snapshot => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              upload.type === "landscape"
                ? commit("progress/SET_PROGRESS_LANDSCAPE", progress, {
                    root: true
                  })
                : commit("progress/SET_PROGRESS_PORTRAIT", progress, {
                    root: true
                  });

              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            error => {
              console.log(error);
            },
            () => {
              upload.task.snapshot.ref.getDownloadURL().then(downloadURL => {
                url[slide.data.type][upload.type] = downloadURL;
                console.log("File available at", downloadURL);
                resolvedPromises++;

                if (resolvedPromises === promises.length) {
                  slides = slides.map(s => {
                    if (s.id === slide.id) {
                      return {
                        ...slide.data,
                        url: url,
                        lastChangedDate: date,
                        editedBy: rootState.user.userProfile.user_id
                      };
                    } else {
                      return s;
                    }
                  });

                  firebase
                    .database()
                    .ref("teams/" + rootState.teams.currentTeam.id)
                    .update({
                      slides: slides
                    })
                    .then(() => {
                      commit("SET_IS_UPLOADING", false);
                      //window.location.reload();

                      dispatch(
                        "notifications/addNotification",
                        {
                          title: "slideUpdated",
                          type: "success",
                          autoRemove: true
                        },
                        { root: true }
                      );

                      commit("SET_REFRESH_SLIDES", true);
                    });
                }
              });
            }
          );
        });
      });
    },
    deleteSlide({ rootState }, id) {
      const slides = rootState.teams.currentTeam.slides.filter(
        slide => slide.id !== id
      );

      firebase
        .database()
        .ref("teams/" + rootState.teams.currentTeam.id)
        .update({
          slides: slides
        });
    },
    updateSlideOrder({ rootState }, slides) {
      firebase
        .database()
        .ref("teams/" + rootState.teams.currentTeam.id)
        .update({
          slides: slides
        });
    },
    async saveTemplateSlide({ rootState, commit, dispatch }, payload) {
      commit("SET_IS_UPLOADING", true);
      let date = new Date();
      let slides = rootState.teams.currentTeam.slides
        ? rootState.teams.currentTeam.slides
        : [];
      const slidesLength = slides.length;
      let promises = [];
      let resolvedPromises = 0;
      let previewUrl = "";

      let payloadTemplate = _.cloneDeep(payload.template);
      let elements = payloadTemplate.elements.map(element => {
        delete element.css;

        if (element.type === "video") {
          delete element.video.url;
        }

        if (element.type === "stack") {
          if (element.children) {
            element.children = removeCssFromStacks(element.children);
          }
        }
        return element;
      });

      let tempElements = payload.tempTemplate.elements;

      const storagePreviewRef = firebase
        .storage()
        .ref()
        .child(
          `teams/${rootState.teams.currentTeam.id}/slides/${payload.slide.id}/preview.png`
        );

      let blob = await fetch(payload.thumbnail).then(r => r.blob());

      promises.push({
        type: "preview",
        task: storagePreviewRef.put(blob)
      });

      const processStackChildren = async (
        children,
        tempChildren,
        parentPath
      ) => {
        for (let index = 0; index < children.length; index++) {
          const child = children[index];
          const tempChild = tempChildren[index];
          const childPath = [...parentPath, "children", index];

          if (child.type === "qr") {
            if (child.image.src !== tempChild.image.src) {
              const blob = await fetch(child.image.src).then(r => r.blob());
              promises.push({
                type: "qr",
                path: childPath,
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (child.type === "image") {
            if (child.image.src !== tempChild.image.src) {
              const blob = await fetch(child.image.src).then(r => r.blob());
              promises.push({
                type: "image",
                path: childPath,
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (child.type === "video") {
            if (child.video.src !== tempChild.video.src) {
              delete child.video.url;

              const blob = child.video.src; // Ensure this is handled correctly
              promises.push({
                type: "video",
                path: childPath,
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (child.type === "stack" && child.children) {
            await processStackChildren(
              child.children,
              tempChild.children,
              childPath
            );
          }
        }
      };

      Promise.all(
        elements.map(async (element, index) => {
          if (element.type === "qr") {
            if (element.image.src !== tempElements[index].image.src) {
              let blob = await fetch(element.image.src).then(r => r.blob());

              promises.push({
                type: "qr",
                path: [index],
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (element.type === "image") {
            if (element.image.src !== tempElements[index].image.src) {
              let blob = await fetch(element.image.src).then(r => r.blob());

              promises.push({
                type: "image",
                path: [index],
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (element.type === "video") {
            if (element.video.src !== tempElements[index].video.src) {
              let blob = element.video.src;

              promises.push({
                type: "video",
                path: [index],
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (element.type === "stack") {
            if (element.children) {
              await processStackChildren(
                element.children,
                tempElements[index].children,
                [index]
              );
            }
          }
        })
      ).then(() => {
        if (promises.length > 0) {
          console.log("promises ", promises);

          Promise.all(promises).then(data => {
            data.map(upload => {
              upload.task.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                snapshot => {
                  // Observe state change events such as progress, pause, and resume
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  var progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                  commit("progress/SET_PROGRESS_PICTURE", progress, {
                    root: true
                  });

                  console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                      console.log("Upload is paused");
                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                      console.log("Upload is running");
                      break;
                  }
                },
                error => {
                  console.log(error);
                },
                () => {
                  console.log("upload task:", upload.index);
                  upload.task.snapshot.ref
                    .getDownloadURL()
                    .then(downloadURL => {
                      console.log("File available at", downloadURL);
                      console.log("upload", upload);

                      let currentElement = elements;
                      if (
                        upload.type === "image" ||
                        upload.type === "video" ||
                        upload.type === "qr"
                      ) {
                        upload.path.forEach((key, j) => {
                          if (j === upload.path.length - 1) {
                            if (upload.type === "qr") {
                              currentElement[key]["image"].src = downloadURL;
                              currentElement[key]["image"].isChanged = true;
                            } else {
                              console.log("inside");

                              currentElement[key][
                                upload.type
                              ].src = downloadURL;
                              currentElement[key][upload.type].isChanged = true;
                              console.log("currentElement", currentElement);
                            }
                          } else {
                            console.log("else", key);

                            currentElement = currentElement[key];
                          }
                        });
                      } else if (upload.type === "preview") {
                        previewUrl = downloadURL;
                      }

                      resolvedPromises++;

                      console.log(
                        "moved forward",
                        resolvedPromises,
                        resolvedPromises === promises.length
                      );

                      if (resolvedPromises === promises.length) {
                        slides[slidesLength] = {
                          ...payload.slide,
                          template: {
                            id: payload.template.id,
                            elements,
                            isParentTemplate: payload.template.isParentTemplate,
                            isRootTemplate: payload.template.isRootTemplate,
                            isLobbySpaceTemplate:
                              payload.template.isLobbySpaceTemplate,
                            teamId: payload.template.teamId
                          },
                          originalDate: date,
                          lastChangedDate: date,
                          createdBy: rootState.user.userProfile.user_id,
                          editedBy: rootState.user.userProfile.user_id,
                          preview: previewUrl
                        };

                        firebase
                          .database()
                          .ref("teams/" + rootState.teams.currentTeam.id)
                          .update({
                            slides: slides
                          })
                          .then(() => {
                            commit("SET_IS_UPLOADING", false);

                            dispatch(
                              "notifications/addNotification",
                              {
                                title: "slideUploaded",
                                type: "success",
                                autoRemove: true
                              },
                              { root: true }
                            );

                            let isParent = "0";
                            if (payload.template.isParentTemplate) {
                              isParent = "1";
                            } else if (payload.template.isRootTemplate) {
                              isParent = "2";
                            } else if (payload.template.isLobbySpaceTemplate) {
                              isParent = "3";
                            } else {
                              isParent = "0";
                            }

                            router.push({
                              name: "Editor",
                              params: {
                                file: "template",
                                isParent: isParent,
                                id: payload.slide.id,
                                templateId: payload.template.id
                              }
                            });

                            commit("SET_REFRESH_SLIDES", true);
                          });
                      }
                    });
                }
              );
            });
          });
        } else {
          slides[slidesLength] = {
            ...payload.slide,
            template: {
              id: payload.template.id,
              elements,
              isParentTemplate: payload.template.isParentTemplate,
              isRootTemplate: payload.template.isRootTemplate,
              isLobbySpaceTemplate: payload.template.isLobbySpaceTemplate,
              teamId: payload.template.teamId
            },
            originalDate: date,
            lastChangedDate: date,
            createdBy: rootState.user.userProfile.user_id,
            editedBy: rootState.user.userProfile.user_id
          };

          firebase
            .database()
            .ref("teams/" + rootState.teams.currentTeam.id)
            .update({
              slides: slides
            })
            .then(() => {
              commit("SET_IS_UPLOADING", false);

              dispatch(
                "notifications/addNotification",
                {
                  title: "slideUploaded",
                  type: "success",
                  autoRemove: true
                },
                { root: true }
              );

              let isParent = "0";
              if (payload.template.isParentTemplate) {
                isParent = "1";
              } else if (payload.template.isRootTemplate) {
                isParent = "2";
              } else if (payload.template.isLobbySpaceTemplate) {
                isParent = "3";
              } else {
                isParent = "0";
              }

              router.push({
                name: "Editor",
                params: {
                  file: "template",
                  isParent: isParent,
                  id: payload.slide.id,
                  templateId: payload.template.id
                }
              });
              commit("SET_REFRESH_SLIDES", true);
            });
        }
      });
    },
    async updateTemplateSlide({ rootState, commit, dispatch }, payload) {
      commit("SET_IS_UPLOADING", true);
      let slides = rootState.teams.currentTeam.slides
        ? rootState.teams.currentTeam.slides
        : [];
      let promises = [];
      let resolvedPromises = 0;
      let previewUrl = "";

      let payloadTemplate = _.cloneDeep(payload.template);
      let elements = payloadTemplate.elements.map(element => {
        delete element.css;

        if (element.type === "stack") {
          if (element.children) {
            element.children = removeCssFromStacks(element.children);
          }
        }

        if (element.type === "video") {
          delete element.video.url;
        }
        return element;
      });

      let tempElements = payload.tempTemplate.elements;

      if (payload.thumbnail) {
        const storagePreviewRef = firebase
          .storage()
          .ref()
          .child(
            `teams/${rootState.teams.currentTeam.id}/slides/${payload.slide.id}/preview.png`
          );

        let blob = await fetch(payload.thumbnail).then(r => r.blob());

        promises.push({
          type: "preview",
          task: storagePreviewRef.put(blob)
        });
      } else {
        this.previewUrl = payload.slide.preview;
      }

      const processStackChildren = async (
        children,
        tempChildren,
        parentPath
      ) => {
        for (let index = 0; index < children.length; index++) {
          const child = children[index];
          const tempChild = tempChildren[index];
          const childPath = [...parentPath, "children", index];

          if (child.type === "qr") {
            if (child.image.src !== tempChild.image.src) {
              const blob = await fetch(child.image.src).then(r => r.blob());
              promises.push({
                type: "qr",
                path: childPath,
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (child.type === "image") {
            if (child.image.src !== tempChild.image.src) {
              const blob = await fetch(child.image.src).then(r => r.blob());
              promises.push({
                type: "image",
                path: childPath,
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (child.type === "video") {
            if (child.video.src !== tempChild.video.src) {
              delete child.video.url;

              const blob = child.video.src; // Ensure this is handled correctly
              promises.push({
                type: "video",
                path: childPath,
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (child.type === "stack" && child.children) {
            await processStackChildren(
              child.children,
              tempChild.children,
              childPath
            );
          }
        }
      };

      Promise.all(
        elements.map(async (element, index) => {
          if (element.type === "qr") {
            if (element.image.src !== tempElements[index].image.src) {
              let blob = await fetch(element.image.src).then(r => r.blob());

              promises.push({
                type: "qr",
                path: [index],
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (element.type === "image") {
            if (element.image.src !== tempElements[index].image.src) {
              let blob = await fetch(element.image.src).then(r => r.blob());

              promises.push({
                type: "image",
                path: [index],
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (element.type === "video") {
            if (element.video.src !== tempElements[index].video.src) {
              let blob = element.video.src;

              promises.push({
                type: "video",
                path: [index],
                task: firebase
                  .storage()
                  .ref()
                  .child(
                    `teams/${rootState.teams.currentTeam.id}/slides/${
                      payload.slide.id
                    }/${create_UUID()}__template`
                  )
                  .put(blob)
              });
            }
          } else if (element.type === "stack") {
            if (element.children) {
              await processStackChildren(
                element.children,
                tempElements[index].children,
                [index]
              );
            }
          }
        })
      ).then(() => {
        if (promises.length > 0) {
          Promise.all(promises).then(data => {
            data.map(upload => {
              upload.task.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                snapshot => {
                  // Observe state change events such as progress, pause, and resume
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  var progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                  commit("progress/SET_PROGRESS_PICTURE", progress, {
                    root: true
                  });

                  console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                      console.log("Upload is paused");
                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                      console.log("Upload is running");
                      break;
                  }
                },
                error => {
                  console.log(error);
                },
                () => {
                  console.log("upload task:", upload.index);
                  upload.task.snapshot.ref
                    .getDownloadURL()
                    .then(downloadURL => {
                      console.log("File available at", downloadURL);

                      let currentElement = elements;
                      if (
                        upload.type === "image" ||
                        upload.type === "video" ||
                        upload.type === "qr"
                      ) {
                        upload.path.forEach((key, j) => {
                          if (j === upload.path.length - 1) {
                            if (upload.type === "qr") {
                              currentElement[key]["image"].src = downloadURL;
                            } else {
                              currentElement[key][
                                upload.type
                              ].src = downloadURL;
                            }
                          } else {
                            console.log("else", key);

                            currentElement = currentElement[key];
                          }
                        });
                      } else if (upload.type === "preview") {
                        previewUrl = downloadURL;
                      }

                      resolvedPromises++;

                      if (resolvedPromises === promises.length) {
                        slides = slides.map(s => {
                          if (s.id === payload.slide.id) {
                            let newSlide = {
                              ...payload.slide,
                              template: {
                                id: payload.template.id,
                                elements,
                                isParentTemplate:
                                  payload.template.isParentTemplate,
                                isRootTemplate: payload.template.isRootTemplate,
                                isLobbySpaceTemplate:
                                  payload.template.isLobbySpaceTemplate,
                                teamId: payload.template.teamId
                              },
                              lastChangedDate: new Date(),
                              editedBy: rootState.user.userProfile.user_id,
                              preview: previewUrl
                            };

                            return newSlide;
                          } else {
                            return s;
                          }
                        });

                        firebase
                          .database()
                          .ref("teams/" + rootState.teams.currentTeam.id)
                          .update({
                            slides: slides
                          })
                          .then(() => {
                            dispatch(
                              "notifications/addNotification",
                              {
                                title: "slideUpdated",
                                type: "success",
                                autoRemove: true
                              },
                              { root: true }
                            );
                            commit("SET_IS_UPLOADING", false);
                            commit("SET_REFRESH_SLIDES", true);
                          });
                      }
                    });
                }
              );
            });
          });
        } else {
          slides = slides.map(s => {
            if (s.id === payload.slide.id) {
              let newSlide = {
                ...payload.slide,
                template: {
                  id: payload.template.id,
                  elements,
                  isParentTemplate: payload.template.isParentTemplate,
                  isRootTemplate: payload.template.isRootTemplate,
                  isLobbySpaceTemplate: payload.template.isLobbySpaceTemplate,
                  teamId: payload.template.teamId
                },
                lastChangedDate: new Date(),
                editedBy: rootState.user.userProfile.user_id
              };
              return newSlide;
            } else {
              return s;
            }
          });

          firebase
            .database()
            .ref("teams/" + rootState.teams.currentTeam.id)
            .update({
              slides: slides
            })
            .then(() => {
              dispatch(
                "notifications/addNotification",
                {
                  title: "slideUpdated",
                  type: "success",
                  autoRemove: true
                },
                { root: true }
              );

              commit("SET_IS_UPLOADING", false);
              commit("SET_REFRESH_SLIDES", true);
            });
        }
      });
    },
    async saveWidgetSlide({ rootState, commit, dispatch }, payload) {
      commit("SET_IS_UPLOADING", true);
      let date = new Date();
      let slides = rootState.teams.currentTeam.slides
        ? rootState.teams.currentTeam.slides
        : [];
      const slidesLength = slides.length;

      slides[slidesLength] = {
        ...payload,
        originalDate: date,
        lastChangedDate: date,
        createdBy: rootState.user.userProfile.user_id,
        editedBy: rootState.user.userProfile.user_id
      };

      firebase
        .database()
        .ref("teams/" + rootState.teams.currentTeam.id)
        .update({
          slides: slides
        })
        .then(() => {
          dispatch(
            "notifications/addNotification",
            {
              title: "slideUploaded",
              type: "success",
              autoRemove: true
            },
            { root: true }
          );

          commit("SET_IS_UPLOADING", false);

          router.push({
            name: "Editor",
            params: {
              file: payload.type,
              id: payload.id
            }
          });
          commit("SET_REFRESH_SLIDES", true);
        });
    },
    async updateWidgetSlide({ rootState, commit, dispatch }, payload) {
      commit("SET_IS_UPLOADING", true);
      let slides = rootState.teams.currentTeam.slides
        ? rootState.teams.currentTeam.slides
        : [];

      slides = slides.map(s => {
        if (s.id === payload.id) {
          return {
            ...payload,
            lastChangedDate: new Date(),
            editedBy: rootState.user.userProfile.user_id
          };
        } else {
          return s;
        }
      });

      firebase
        .database()
        .ref("teams/" + rootState.teams.currentTeam.id)
        .update({
          slides: slides
        })
        .then(() => {
          dispatch(
            "notifications/addNotification",
            {
              title: "slideUpdated",
              type: "success",
              autoRemove: true
            },
            { root: true }
          );

          commit("SET_IS_UPLOADING", false);
          commit("SET_REFRESH_SLIDES", true);
        });
    },
    fetchWidgetData({ commit }) {
      const widgetRef = db.ref("widgets");

      return new Promise(resolve => {
        widgetRef.on("value", snapshot => {
          let data = snapshot.val();
          commit("SET_WIDGET_DATA", data);
          resolve();
        });
      });
    },
    duplicateSlide({ rootState, dispatch }, slide) {
      const slides = rootState.teams.currentTeam.slides;

      slides.push(slide);

      firebase
        .database()
        .ref("teams/" + rootState.teams.currentTeam.id)
        .update({
          slides: slides
        });

      dispatch(
        "notifications/addNotification",
        {
          title: "slideDuplicated",
          type: "success",
          autoRemove: true
        },
        { root: true }
      );
    }
    // async fetchLocationData(_, location) {
    //   try {
    //     const response = await axios.get(
    //       `https://api.openweathermap.org/geo/1.0/direct?q=${location}&limit=5&appid=2dacca683bc624c3e923bf39b629cdc5`
    //     );
    //     return response.data;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
  }
};
