<template>
  <div class="register">
    <div class="register__wrapper">
      <div class="register__logo">
        <div class="register__logo-icon">
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#7E93FF" />
            <path
              d="M9.8999 15.3999V17.5999C9.8999 22.46 13.8398 26.3999 18.6999 26.3999H30.2499"
              stroke="white"
              stroke-width="2.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.1 13.2001H18.7C16.27 13.2001 14.3 15.1701 14.3 17.6001V17.6001C14.3 20.0302 16.27 22.0001 18.7 22.0001H29.7C32.1301 22.0001 34.1 23.9701 34.1 26.4001V26.4001C34.1 28.8302 32.1301 30.8001 29.7 30.8001H23.1"
              stroke="white"
              stroke-width="2.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="register__logo-text">LobbySpace</div>
      </div>
      <h1 class="register__title">
        {{ $t("pages.register.title") }}
      </h1>
      <Card class="register__card">
        <form class="register__form" @submit.prevent>
          <label
            class="register__label text__small--bold"
            for="register__input-name"
            >{{ $t("pages.register.nameLabel") }}</label
          >
          <input
            class="register__input"
            v-model.trim="name"
            type="text"
            placeholder="Max Mustermann"
            id="register__input-name"
          />
          <label
            class="register__label text__small--bold"
            for="register__input-email"
            >{{ $t("pages.register.emailLabel") }}</label
          >
          <input
            class="register__input"
            v-model.trim="email"
            type="text"
            placeholder="you@email.com"
            id="register__input-email"
          />

          <label
            class="register__label text__small--bold"
            for="register__input-password"
            >{{ $t("pages.register.passwordLabel") }}</label
          >
          <input
            class="register__input"
            v-model.trim="password"
            type="password"
            placeholder="******"
            id="register__input-password"
          />

          <label
            class="register__label text__small--bold"
            for="register__input-team"
            >{{ $t("pages.register.teamLabel") }}</label
          >
          <input
            class="register__input"
            v-model.trim="teamName"
            type="text"
            :placeholder="$t('pages.register.placeholderTeam')"
            id="register__input-team"
          />

          <div class="register__brand-import">
            <div class="register__brand-import-text">DSE 2024</div>
            <Toggle
              class="register__brand-import-toggle"
              v-model="dseFeature"
            ></Toggle>
          </div>

          <Button @click="register" class="register__button--register">
            {{ $t("pages.register.registerButton") }}
          </Button>
        </form>
        <div
          v-if="feedback !== ''"
          class="register__error text__small--regular"
        >
          {{ feedback }}
        </div>
      </Card>

      <Card class="register__card">
        <div class="register__card-title">
          {{ $t("pages.register.already") }}
        </div>
        <Button
          theme="secondary"
          class="register__button--login"
          @click="goToLogin"
        >
          {{ $t("pages.register.loginButton") }}
        </Button>
      </Card>

      <Card class="register__card">
        <div class="register__card-title">
          {{ $t("pages.register.registerWith") }}
        </div>
        <label
          class="register__label text__small--bold"
          for="register__input-sso-team"
          >{{ $t("pages.register.teamLabel") }}</label
        >
        <input
          class="register__input"
          v-model.trim="ssoTeamName"
          type="text"
          :placeholder="$t('pages.register.placeholderTeam')"
          id="register__input-sso-team"
        />
        <div class="register__brand-import">
          <div class="register__brand-import-text">DSE 2024</div>
          <Toggle
            class="register__brand-import-toggle"
            v-model="dseFeatureMS"
          ></Toggle>
        </div>
        <Button
          theme="secondary"
          class="register__button--register"
          @click="registerWithMicrosoft"
        >
          <inline-svg
            class="register__card-sso-icon"
            :src="require(`../assets/icons/Microsoft_SSO.svg`)"
            aria-label="Microsoft Logo"
          ></inline-svg>
          {{ $t("pages.register.microsoftSSO") }}
        </Button>
        <div
          v-if="ssoFeedback !== ''"
          class="register__error text__small--regular"
        >
          {{ ssoFeedback }}
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { provider } from "../firebase/init";
import { mapActions } from "vuex";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Toggle from "@/components/Toggle";

export default {
  components: {
    Button,
    Card,
    Toggle
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      teamName: "",
      ssoTeamName: "",
      performingRequest: true,
      feedback: "",
      ssoFeedback: "",
      dseFeature: false,
      dseFeatureMS: false
    };
  },
  methods: {
    ...mapActions("user", ["setCurrentUser", "fetchUserProfile"]),
    ...mapActions("teams", ["createFirstTeam"]),
    goToLogin() {
      this.$router.push({ path: "/login" });
    },
    register() {
      if (this.name && this.email && this.password && this.teamName) {
        this.feedback = null;
        this.load = true;

        firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then(user => {
            firebase
              .database()
              .ref("users/" + user.user.uid)
              .set({
                name: this.name,
                user_id: user.user.uid,
                email: this.email,
                language: "en"
              });
            this.setCurrentUser(user);
            this.createFirstTeam({
              teamName: this.teamName,
              email: this.email,
              name: this.name,
              id: user.user.uid,
              dseFeature: this.dseFeature
            })
              .then(() => {
                // this.fetchUserProfile();
                if (this.dseFeature) {
                  this.$router.push({
                    name: "BrandImport"
                  });
                } else {
                  this.$router.push({
                    name: "Dashboard"
                  });
                }
                this.load = false;
              })
              .catch(err => {
                this.feedback = err.message;
              });
          })
          .catch(err => {
            this.feedback = err.message;
          });
      } else {
        this.feedback = this.$t("pages.register.fillAllFields");
      }
    },
    registerWithMicrosoft() {
      if (this.ssoTeamName) {
        this.ssoFeedback = null;
        this.load = true;

        firebase
          .auth()
          .signInWithPopup(provider)
          .then(user => {
            firebase
              .database()
              .ref("users/" + user.user.uid)
              .set({
                name: user.user.displayName,
                user_id: user.user.uid,
                email: user.user.email,
                language: "en",
                sso: "Microsoft"
              });
            this.setCurrentUser(user.user);
            this.createFirstTeam({
              teamName: this.ssoTeamName,
              email: user.user.email,
              name: user.user.displayName,
              id: user.user.uid,
              sso: "Microsoft",
              dseFeature: this.dseFeatureMS
            })
              .then(() => {
                // this.fetchUserProfile();
                if (this.dseFeatureMS) {
                  this.$router.push({
                    name: "BrandImport"
                  });
                } else {
                  this.$router.push({
                    name: "Dashboard"
                  });
                }
                this.load = false;
              })
              .catch(err => {
                this.ssoFeedback = err.message;
              });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.feedback = this.$t("pages.register.fillAllFields");
      }
    }
  }
};
</script>

<style lang="scss">
.register {
  min-height: 100vh;
  padding-top: 42px;
  @apply flex justify-center bg-ls-gray-100;

  &__wrapper {
    @apply px-3 w-full;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      margin-left: 8px;
    }
  }

  &__title {
    text-align: center;
    margin: 57px 0 36px;
    font-size: 48px;
    font-weight: 600;
  }

  &__card {
    @apply flex flex-col p-6 bg-white rounded mb-6;
    //box-shadow: $box-shadow;

    &-title {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__card-sso-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &__form {
    @apply flex flex-col;
  }

  &__label {
    margin-bottom: 4px;
  }

  &__input {
    height: 42px;
    @apply rounded-lg px-2 bg-ls-gray-100 mb-4;
  }

  &__brand-import {
    @apply flex justify-between items-center mb-4 text-sm font-semibold;
  }

  &__button--login {
    width: fit-content;
    margin: 16px auto 0;
  }

  &__error {
    @apply text-red-600 mt-2;
  }
}

@media screen and (min-width: 900px) {
  .register {
    &__wrapper {
      @apply px-0 w-auto;
    }

    &__card {
      width: 540px;
    }
  }
}
</style>
