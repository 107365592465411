<template>
  <div class="brand-manager" v-if="!isLoading">
    <SideBar class="brand-manager__sidebar--desktop" :list="brandLinks" />
    <SideBarMobile class="brand-manager__sidebar--mobile" :list="brandLinks" />
    <div class="brand-manager__wrapper">
      <div class="brand-manager__heading">
        <div class="brand-manager__title">
          {{ $t(`pages.brandmanager.${$route.params.id}.heading`) }}
        </div>
      </div>
      <component class="brand-manager__content" :is="componentName"></component>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Button from "@/components/Button";
import SideBarMobile from "@/components/SideBarMobile";
import Colors from "./Colors";
import Logo from "./Logo";
import Typography from "./Typography";
import Fonts from "./Fonts";
import { brand } from "@/schema/sideBar";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SideBar,
    Button,
    SideBarMobile,
    Colors,
    Logo,
    Typography,
    Fonts
  },
  data: () => ({
    isLoading: true
  }),
  computed: {
    brandLinks() {
      let links = brand.map(link => {
        return {
          link: link.link,
          name: this.$t(`pages.brandmanager.nav.${link.name}`)
        };
      });

      if (this.team.dseFeature) {

        links.push({
          link: '/brand-import',
          name: "Brand Import"
        });
      }

      return links;
    },
    componentName() {
      return this.$route.params.id;
    },
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      childSettings: "getChildSettings"
    })
  },
  watch: {
    parentTeam: {
      handler() {
        if (!this.childSettings.brandManager) {
          this.$router.push({
            name: "Dashboard"
          });
        }
      },
      deep: true
    }
  },
  created() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchCurrentTeam(this.user.currentTeam).then(() => {
          if (this.team.parentTeam) {
            this.fetchParentTeam(this.team.parentTeam).then(() => {
              if (this.childSettings && !this.childSettings.brandManager) {
                this.$router.push({
                  name: "Dashboard"
                });
              } else {
                this.isLoading = false;
              }
            });
          } else {
            this.isLoading = false;
          }
        });
      });
    } else if (storedTeam) {
      this.fetchCurrentTeam(storedTeam).then(() => {
        if (this.team.parentTeam) {
          this.fetchParentTeam(this.team.parentTeam).then(() => {
            if (this.childSettings && !this.childSettings.brandManager) {
              this.$router.push({
                name: "Dashboard"
              });
            } else {
              this.isLoading = false;
            }
          });
        } else {
          this.isLoading = false;
        }
      });
    } else {
      this.fetchCurrentTeam(this.user.currentTeam).then(() => {
        if (this.team.parentTeam) {
          this.fetchParentTeam(this.team.parentTeam).then(() => {
            if (this.childSettings && !this.childSettings.brandManager) {
              this.$router.push({
                name: "Dashboard"
              });
            } else {
              this.isLoading = false;
            }
          });
        } else {
          this.isLoading = false;
        }
      });
    }
  },
  methods: {
    ...mapActions("teams", ["fetchCurrentTeam", "fetchParentTeam"])
  }
};
</script>

<style lang="scss">
.brand-manager {
  min-height: 100vh;

  &__sidebar--desktop {
    display: none;
  }

  &__wrapper {
    min-height: 100vh;
    width: 100%;
    padding: 4rem 1.5rem 1.5rem;
  }

  &__content {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .brand-manager {
    &__sidebar--desktop {
      display: block;
    }

    &__sidebar--mobile {
      display: none;
    }

    &__wrapper {
      margin-left: 208px;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2.25rem 0;
    }

    &__heading {
      @apply mb-6;
      width: 744px;
    }

    &__title {
      font-weight: 600;
      font-size: 28px;
    }

    &__content {
      width: 744px;
    }
  }
}
</style>
